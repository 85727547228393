import React from 'react';

const Footer = () => {
    return (
        <>
        <div className="hidden w-full flex-row fixed items-center pl-3 bg-white z-10 bottom-0 md:flex">
            <p>
                Klippingeslagteren &#183; Tværvej 5 &#183; 4672 Klippinge &#183;
                Tlf: <a href="tel:56578033"> 56 57 80 33</a> &#183;
                <a href="mailto:post@klippingeslagteren.dk" className='pl-2 underline hover:text-red'>
                    post@klippingeslagteren.dk
                </a>
                &nbsp;&#183; CVR-nummer: 25672429
            </p>
        </div>
        <div className='flex flex-row w-full bottom-0 px-4 py-4 border-t sm:justify-between md:hidden'>
            <div className='flex flex-col sm:text-left'>
                <p>Klippingeslagteren</p>
                <p>Tværvej 5</p>
                <p>4672 Klippinge</p>
                <p>Tlf: <a href="tel:56578033" > 56 57 80 33</a></p>
                <a href="mailto:post@klippingeslagteren.dk" className='underline hover:text-red'>
                    post@klippingeslagteren.dk
                </a>
                CVR-nummer: 25672429
            </div>
        </div>
        </>
    )
}

export default Footer